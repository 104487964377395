import React, { useEffect } from "react";
import Login from "./Login";
import Projects from "./Projects";
import Todos from "./Todos";
import Todo from "./Todo";
import Favourites from "./Favourites";
import Settings from "./Settings";
import { Tab, Box, Paper } from "@mui/material";

import { TabContext, TabPanel, TabList as Tabs } from "@mui/lab";
import { useSelector, useDispatch } from 'react-redux'
import { logout } from "../reducers/userReducer";
import { setAuthData, useStartIdle, useStartTodoSession, useUpdateTodoSession } from "../services/creativedeskApi";
import { initialIdleStarted, setProject, setScreen, setTodo } from "../reducers/deskReducer";
import { Favorite, History, Timer } from "@mui/icons-material";
import SettingsIcon from '@mui/icons-material/Settings';
import CheckIcon from '@mui/icons-material/Check';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { TABS, TODO_NOTIFICATON_INTERVAL, IDLE_TIME, SPARE_TIME_PROJECT_ID, TEMP_PROJECT_ID, DESK_URL } from "../services/constants";
import StatusBar from "../components/StatusBar";
import Session from "./Session";
import {  
  prestop as sessionPreStop, 
  stop as sessionStop,
  updateSent as sessionUpdateSent,
  start as timerStart,
  stopIdle as sessionStopIdle,
  markNotificationSent,
  updateNote,
 } from "../reducers/sessionReducer";
import { toHours, toHoursAndMinutes } from "../services/momentHelper";
import { addTask } from "../reducers/favReducer";
import messageBus from "../services/messageBus";
import Dashboard from "./Dashboard";
import { versionHandler } from "../services/helpers";
import DeskBookmarks from "./DeskBookmarks";
import linkOpener from "../services/linkOpener";
import MissingSessionNotesAlert from '../components/MissingSessionNotesAlert';

export default function Desk() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);
    const desk = useSelector(state => state.desk);
    const session = useSelector(state => state.session);
    
    const handleForbidden = (forgetUsername = false) => {
      dispatch(sessionPreStop());
      dispatch(sessionStop());
      dispatch(sessionStopIdle());
      dispatch(logout({forgetUsername}));
      dispatch(initialIdleStarted(false));
      dispatch(setScreen(TABS.session));
      messageBus.send('desk-stop');
      reloadClient(true);
    }
    
    const reloadClient = (force = false) => {
      if (force || (new Date).getDate() != desk.startedOn) {
        window.location.reload();
      }
    }

    setAuthData(token, handleForbidden);
    const chanteTab = (e, value) => {
      dispatch(setScreen(value));
    }    

    const sessionMutation = useUpdateTodoSession();
    const updateSession = () => {
      sessionMutation.mutate({
        sessionId: session.sessionId,
        note: session.note,
        updateTime: true,
        todoId: session.todoId
      },{
        onSuccess: () => {        
          dispatch(sessionUpdateSent());
        }
      });    
    }

    const stopSession = (markAsDone, updateTime=true, next=null) => {
      dispatch(sessionPreStop());
      sessionMutation.mutate({
        sessionId: session.sessionId,
        note: session.note,
        done: markAsDone,
        updateTime
      },{
        onSuccess: () => {        
          dispatch(sessionStop());
          if(session.projectId == TEMP_PROJECT_ID || session.note.endsWith('eee')) {
            linkOpener(`${DESK_URL}/todo_sessions/${session.sessionId}/edit`);
          }
          if(next) {
            next();
          } else if(!session.idle && desk.settings.startIdleAfterTodo) {
            // Good moment to get new version of desk
            if(desk.settings.startWithIdle) {
              reloadClient();
            }
            startIdle();
          } else {
            if(!desk.settings.startWithIdle) {
              reloadClient();
            }
            dispatch(sessionStopIdle());
            messageBus.send('desk-stop');
          }
        }
      });    
    }

    const startTodoMutation = useStartTodoSession();
    const startTodo = (todoId, todoName, todoType, projectId, projectName) => {
      const start = () => {
        startTodoMutation.mutate(todoId,{
          onSuccess: (data) => {
            dispatch(timerStart({
              ...data, 
              todoName,
              projectId,
              projectName
            }));
            if(projectId == SPARE_TIME_PROJECT_ID) {
              dispatch(updateNote('...zzzZZZ'));
              messageBus.send('desk-spareTime');
            } else {
              messageBus.send('desk-start');
            }
            dispatch(setScreen(TABS.session));
            if(projectId !== SPARE_TIME_PROJECT_ID && projectId !== TEMP_PROJECT_ID) {
              dispatch(addTask({todo: {id: todoId, name: todoName, todoType}, project: {id: projectId, name: projectName}, fav: false}));   
            }
          }      
        });      
      }
      if(session?.time) {
        stopSession(false, true, start);
      } else {
        start();
      }
    }

    const startIdleMutation = useStartIdle();
    const startIdle = () => {      
      startIdleMutation.mutate({},{
        onSuccess: (data) => {
          dispatch(timerStart({
            ...data, 
            idle: true,
            todoName: 'Idle'
          }));
          messageBus.send('desk-idle');
          // dispatch(setScreen(TABS.session));
        }      
      });      
    }

    const openTodo = (projectId, todoId) => {
      dispatch(setProject({id: projectId}));
      dispatch(setTodo({id: todoId}));
      dispatch(setScreen(TABS.todo));
    }

    const openTodoHandler = (e) => {
      openTodo(e.detail.projectId, e.detail.todoId);
    }
    
    const schemeHandler = (e, url) => {      
      try {
        const { projectId, todoId } = parseProtocol(url);        
        openTodo(projectId, todoId);
      } catch (e) {
        console.log(e);
      }
    }

    const checkAndNotifiyAboutEndOfIdle = () => {
      if(session.idle && session.time >= IDLE_TIME) {
        messageBus.send('desk-message', {
          title: "Idle time is over",
          body: "The idle time is over. You can start a new session.",  
          timeoutType: "never"
        });
        messageBus.send('desk-focus');
        stopSession(false);
      }
    }

    const notifyAboutSessionLength = () => {
      const currentPeriodId = 'l-' + Math.floor(session.time / TODO_NOTIFICATON_INTERVAL);
      if(session.todoId && currentPeriodId!='l-0' 
        && !session.notificationsSent.includes(currentPeriodId)) {
        messageBus.send('desk-message', {
          title: `Work time ${toHours(session.time)}`,
          body: `You have been working on ${session.todoName} for ${toHoursAndMinutes(session.time)}.`,  
          timeoutType: 'default',
          closeButtonText: 'OK'
        });
        dispatch(markNotificationSent(currentPeriodId));
      }
    }

    useEffect(() => {      
      messageBus.on('open-todo', schemeHandler);   
      window.addEventListener('internal-opentodo', openTodoHandler);   
      messageBus.on('desk-ver', versionHandler);
      if(session.pendingUpdateDate) {
        updateSession();
        checkAndNotifiyAboutEndOfIdle();
        notifyAboutSessionLength();               
      }
      
      if(token && desk.settings.startWithIdle && !desk.initialIdle) {        
        startIdle();
        dispatch(initialIdleStarted(true));
      } 
      
      return () => {        
        messageBus.removeAllListeners('desk-ver');
        window.removeEventListener('internal-opentodo', openTodoHandler);
        messageBus.removeAllListeners('open-todo');
      }
    }, [session.pendingUpdateDate, session.todoId, token]);

    if (token) {
        return (
          <Box sx={{
            height: "100vh", 
            display: "flex", flexDirection: 'column',
            maxWidth: "1024px",
            margin: "auto",
            }}>                      
          <TabContext value={desk.screen}>
            <Box sx={{flexGrow: 2}}>
            
            <Paper sx={{ 
                position: "sticky",
                top: 0, 
                zIndex: 100}} elevation={3}>
              <Tabs onChange={chanteTab} 
                variant="scrollable"
                scrollButtons={false}
                value={desk.screen}
              >
                <Tab value={TABS.session} label={!desk.settings.onlyIcons && "Session" }
                  icon={<Timer fontSize="medium" color={session.todoId ? 'success' : !session.sessionId ? 'error' : 'warning'}/>} 
                  iconPosition={!desk.settings.onlyIcons ? "start" : "top"}
                  sx={{
                    minHeight: 'unset',
                  }}
                />
                <Tab value={TABS.projects} label={!desk.settings.onlyIcons && "Projects"} icon={desk.settings.onlyIcons && <FolderCopyIcon fontSize="medium"/>}/>
                <Tab value={TABS.todos} label={!desk.settings.onlyIcons && "Todos"} icon={desk.settings.onlyIcons &&  <ChecklistIcon fontSize="medium"/>}
                    disabled={desk.project == null}/>
                <Tab value={TABS.todo} label={!desk.settings.onlyIcons && "Todo"} icon={desk.settings.onlyIcons && <CheckIcon fontSize="medium"/>}
                    disabled={desk.todo == null}/>

                <Tab value={TABS.history} icon={<History fontSize="medium"/>}/>
                <Tab value={TABS.favorites} icon={<Favorite fontSize="medium"/>}/>
                <Tab value={TABS.settings} icon={<SettingsIcon fontSize="medium"/>}/>    
                <Tab value={TABS.dashoard} sx={{display: 'none'}}/>    
                          
              </Tabs>
            </Paper>
            <MissingSessionNotesAlert />
            <TabPanel value={TABS.projects}><Projects /></TabPanel>
            <TabPanel value={TABS.todos}><Todos projectId={desk.project?.id} startTodo={startTodo}/></TabPanel>
            <TabPanel value={TABS.todo}>
              <Todo projectId={desk.project?.id} todoId={desk.todo?.id} startTodo={startTodo} isStarting={startTodoMutation.isPending}/>
            </TabPanel>            
            <TabPanel value={TABS.session}>
              <Session stopSession={stopSession} startIdle={startIdle} startIdleMutation={startIdleMutation} sessionMutation={sessionMutation} startTodo={startTodo}/>
            </TabPanel>             
            <TabPanel value={TABS.history}><Favourites area="history" areaName="History" withDelete startTodo={startTodo}/></TabPanel>
            <TabPanel value={TABS.favorites}><Favourites area="bookmarks" areaName="Favourites" withDelete startTodo={startTodo}/><DeskBookmarks /></TabPanel>
            <TabPanel value={TABS.settings}><Settings logoutCallback={handleForbidden}/></TabPanel>
            <TabPanel value={TABS.dashoard}><Dashboard/></TabPanel>
            
            </Box>
          </TabContext>
          
          <StatusBar startTodo={startTodo}/>
          </Box>
        )
    } else {
      return(
        <Login />
      )
    }
    
}

export const parseProtocol = (url) => { 
  const [protocol, ids] = url.replaceAll("/", "").replace("@", "-").split(":");
  if (protocol !== "desk") {
    throw new Error("Invalid protocol");
  }
  const [todoId, projectId] = ids.split("-");
  if (isNaN(projectId) || isNaN(todoId)) {
    throw new Error("Invalid IDs");
  }
  return { projectId: parseInt(projectId), todoId: parseInt(todoId) };
}
  

