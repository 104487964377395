import { BugReport } from "@mui/icons-material";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import QuizIcon from '@mui/icons-material/Quiz';
import BiotechIcon from '@mui/icons-material/Biotech';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import StarsIcon from '@mui/icons-material/Stars';
import { 
  Chip,
  List, 
  ListItem, 
  ListItemButton, 
  ListItemText, 
  LinearProgress, 
  ListItemIcon } from "@mui/material";

import {
  TODO_ID_THAT_SHOULD_NOT_BE_MARKED_AS_OVERTIME_1,
  TODO_ID_THAT_SHOULD_NOT_BE_MARKED_AS_OVERTIME_2
} from "../variables/constants";

export default function todosList({todosList, currentTodo, selectTodo}) {
  const noOvertimeTodos = [
    TODO_ID_THAT_SHOULD_NOT_BE_MARKED_AS_OVERTIME_1,
    TODO_ID_THAT_SHOULD_NOT_BE_MARKED_AS_OVERTIME_2
  ]

  return (
    <List >
    {todosList?.map(todo => (
      <ListItem key={todo.id} disablePadding>
        <ListItemButton onClick={selectTodo(todo)} selected={todo.id == currentTodo?.id}>
          <ListItemIcon>{typeToIcon(todo.todoType)}</ListItemIcon>
          <ListItemText>
            {todo.name}
            {
              todo.progress.percent > 100 && !noOvertimeTodos.includes(todo.id) && 
              <Chip label={`${todo.progress.percent}%`} color="error" size="small" sx={{ml:2}}/>
            }
            <LinearProgress sx={{mt:1}} {...todo.progress}/>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    ))}
    </List>
  )
}

export const typeToIcon = (type) => {
  switch(type) {
    case "bug":
      return <BugReport color="error" fontSize="large"/>
    case 'support':
      return <SupportAgentIcon color="secondary" fontSize="large"/>
    case 'feature':
      return <FeaturedPlayListIcon color="info" fontSize="large"/>
    case 'modification':
      return <QuizIcon color="secondary" fontSize="large"/>
    case 'test':
      return <BiotechIcon color="secondary" fontSize="large"/>
    case 'management':
      return <ManageAccountsIcon color="secondary" fontSize="large"/>
    case 'new extra':
      return <StarsIcon color="secondary" fontSize="large"/>
    default:
      return <QuizIcon/>
  }
}