import { useMemo, useState } from "react";
import { Box, Alert, AlertTitle, Button, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { OpenInBrowser, Replay } from "@mui/icons-material";
import { useMissingSessionNotes } from "../services/creativedeskApi";
import { DESK_URL } from "../services/constants";
import linkOpener from "../services/linkOpener";


export default function MissingSessionNotesAlert() {
    const [lastTimeRefresh, setLastTimeRefresh] = useState(Date.now())
    const missingSesionNotes = useMissingSessionNotes()
    const refresh = () => {
        const now = Date.now()

        if (now - lastTimeRefresh < 5000) {
            return
        }
        
        setLastTimeRefresh(now)
        missingSesionNotes.refetch()
    }

    const missingSessionAlerts = useMemo(() => {
        const alerts = []

        if (missingSesionNotes.data?.totalEmptyNotEditableSessions > 0) {
            alerts.push ({
                id: 'missing_monthly_notes',
                title: 'Missing session notes for this month',
                severity: 'error',
                message: `You already have ${missingSesionNotes.data.totalEmptyNotEditableSessions} empty session ${missingSesionNotes.data.totalEmptyNotEditableSessions > 1 ? 'notes' : 'note'} that you can't edit on your own! Please contact your Project Manager or Head of Office to resolve this issue. Remember to keep your session notes up to date!`,
                linkUrl: null
            })
        }

        if (missingSesionNotes.data?.missingNotesLastWorkingDay) {
            alerts.push ({
                id: 'missing_last_working_day_notes',
                title: `Missing session notes from ${missingSesionNotes.data.isMonday ? 'last week' : 'yesterday'}!`,
                severity: 'warning',
                message: `You missed session notes ${missingSesionNotes.data.isMonday ? 'last week' : 'yesterday'}! Add them immediately!`,
                linkUrl: `${DESK_URL}/${missingSesionNotes.data?.linkToPreviousWorkingDay}`
            })
        }

        if (missingSesionNotes.data?.missingNotesToday) {
            alerts.push ({
                id: 'missing_today_notes',
                title: 'Missing session notes for today',
                severity: 'info',
                message: 'You have some missing session notes today. A friendly reminder to add them!',
                linkUrl: `${DESK_URL}/${missingSesionNotes.data?.linkToToday}`
            })
        }

        return alerts
    })

    return (
        <>
            { missingSessionAlerts.length > 0 && (
                <Paper
                    variant="elevation"
                    elevation={3}
                    sx={{p: 2, m: 2}}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid xs={8}>
                            <Typography variant="h2">
                                Missing Session Notes!
                            </Typography>
                        </Grid>
                        <Grid xs={4} sx={{textAlign: "right"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Replay />}
                                onClick={refresh}
                                target="_blank"
                            >
                                Refresh
                            </Button>
                        </Grid>
                    </Grid>
                    { missingSessionAlerts.map((alert) => (
                        <Box
                            key={alert.id}
                            sx={{ mt: 2, fontSize: 20 }}
                        >
                            <Alert variant="filled" severity={alert.severity}>
                                <AlertTitle>{ alert.title }</AlertTitle>
                                { alert.message }
                                { alert.linkUrl && (
                                    <Box sx={{mt: 2}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<OpenInBrowser />}
                                            href={alert.linkUrl}
                                            onClick={linkOpener}
                                            target="_blank"
                                        >
                                            Add missing notes
                                        </Button>
                                    </Box>
                                )}
                            </Alert>
                        </Box>
                    ))}
                </Paper>
            )}
        </>
    )
}